//
// DANCESPORT STARTUP
// ==================
// Launches the React dancer management app for Dancesport
// New Zealand.
//
// Revision History
// ================
// 22.04.2022 1.0 BRD Original version.
//
import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from "react-router-dom";
import 'bulma/css/bulma.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './index.css';
import Dancesport from './Dancesport';

localStorage.setItem("userID", "");
localStorage.setItem("userName", "");
localStorage.setItem("isAdmin", false)

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Router>
      <Dancesport />
    </Router>
  </React.StrictMode>
);
