//
// MANAGE CONFIGURATION
// ====================
// This module allows administrators to manage the default settings and configuration
// options used by other parts of the Dancesport application.
//
// This single-record maintenance page is the template that defines the style for all
// other data entry and management pages. Note how it implements a set of declarations
// for each of the functions that are called by the main page, which is declared and
// returned at the bottom of the file. Additional page elements such as the YesNo modal
// dialog have to be rendered in-line at run-time even though they start as invisible.
// They are declared as React Fragments so that the structure of the main page is less
// cluttered.
//
// Revision History
// ================
// 07.10.2022 BRD Original version.
// 12.10.2022 BRD Re-factored to implement the modal dialog as a fragment.
// 18.10.2022 BRD Implemented method to detect when a data variable has changed so that
//                this can be detected by the Cancel function to warn the user.
// 19.01.2023 BRD Re-structured the Configuration table to add all the new age
//                fields. Tidied up dialog interactions.
// 03.03.2023 BRD Upgraded all data requests to provide JSON Web Tokens (JWTs) during
//                communication with the back end. This addresses some outstanding issues
//                on authentication and authorisation.
//
import React from "react"
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {getBaseURL } from './getBaseURL';
import Axios from 'axios';

import "bulma/css/bulma.css";
import "./Dancesport.css";
import { PageHeader } from "./PageHeader";
import { editingStates, dialogs } from "./Constants";

const baseURL = getBaseURL();
//
// ManageConfiguration()
// =====================
export function ManageConfiguration() {
    const [errors, setErrors] = useState([]);
    const axios = Axios;
    const navigate = useNavigate();
    const JWT = sessionStorage.getItem('JWT');

    //
    // Authenticate user
    // =================
    // Checks to see if the local storage has a UserID value set to ensure
    // that only authenticated users can access this page. Users who are not
    // authenticated are directed back to the Sign-on page.
    //
    const UserID = sessionStorage.getItem('userID');
    useEffect(() => {
        if (!UserID) {
            return navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [UserID]);

    //
    // Define data fields and track them
    // =================================
    // Defines each of the editable fields and tracks when they change
    // during editing. The savedData variable holds a copy of the data
    // returned by the getConfiguration() function. This is used to
    // later determine if any data fields has changed.
    //
    const [Lw_Sub_Juvenile_Age, setLw_Sub_Juvenile_Age] = useState(0);
    const [Up_Sub_Juvenile_Age, setUp_Sub_Juvenile_Age] = useState(0);
    const [Lw_Juvenile_Age, setLw_Juvenile_Age] = useState(0);
    const [Up_Juvenile_Age, setUp_Juvenile_Age] = useState(0);
    const [Lw_Junior_Age, setLw_Junior_Age] = useState(0);
    const [Up_Junior_Age, setUp_Junior_Age] = useState(0);
    const [Lw_Youth_Age, setLw_Youth_Age] = useState(0);
    const [Up_Youth_Age, setUp_Youth_Age] = useState(0);
    const [Lw_Adult_Age, setLw_Adult_Age] = useState(0);
    const [Up_Adult_Age, setUp_Adult_Age] = useState(0);
    const [Lw_Masters_1_Age, setLw_Masters_1_Age] = useState(0);
    const [Up_Masters_1_Age, setUp_Masters_1_Age] = useState(0);
    const [Lw_Masters_2_Age, setLw_Masters_2_Age] = useState(0);
    const [Up_Masters_2_Age, setUp_Masters_2_Age] = useState(0);
    const [Lw_Masters_3_Age, setLw_Masters_3_Age] = useState(0);
    const [Up_Masters_3_Age, setUp_Masters_3_Age] = useState(0);
    const [Lw_Masters_4_Age, setLw_Masters_4_Age] = useState(0);
    const [Up_Masters_4_Age, setUp_Masters_4_Age] = useState(0);
    const [Lw_Masters_5_Age, setLw_Masters_5_Age] = useState(0);
    const [Up_Masters_5_Age, setUp_Masters_5_Age] = useState(0);

    const [savedData, setSavedData] = useState([]);

    //
    // isChanged()
    // ===========
    // Uses the savedData object that was returned from the getConfiguration() function to
    // provide a point of reference to track changes. This is used to implement the Cancel
    // functionality if the user attempts to cancel rather than update after changes have
    // been made.
    //
    function isChanged() {
        if (Lw_Sub_Juvenile_Age !== savedData.lw_sub_juvenile_age ||
            Up_Sub_Juvenile_Age !== savedData.up_sub_juvenile_age ||
            Lw_Juvenile_Age !== savedData.lw_juvenile_age ||
            Up_Juvenile_Age !== savedData.up_juvenile_age ||
            Lw_Junior_Age !== savedData.lw_junior_age ||
            Up_Junior_Age !== savedData.up_junior_age ||
            Lw_Youth_Age !== savedData.lw_youth_age ||
            Up_Youth_Age !== savedData.up_youth_age ||
            Lw_Adult_Age !== savedData.lw_adult_age ||
            Up_Adult_Age !== savedData.up_adult_age ||
            Lw_Masters_1_Age !== savedData.lw_masters_1_age ||
            Up_Masters_1_Age !== savedData.up_masters_1_age ||
            Lw_Masters_2_Age !== savedData.lw_masters_2_age ||
            Up_Masters_2_Age !== savedData.up_masters_2_age ||
            Lw_Masters_3_Age !== savedData.lw_masters_3_age ||
            Up_Masters_3_Age !== savedData.up_masters_3_age ||
            Lw_Masters_4_Age !== savedData.lw_masters_4_age ||
            Up_Masters_4_Age !== savedData.up_masters_4_age ||
            Lw_Masters_5_Age !== savedData.lw_masters_5_age ||
            Up_Masters_5_Age !== savedData.up_masters_5_age ) {
            return true;
        } else {
            return false;
        }
    }

    //
    // Editing state control
    // =====================
    // This section implements a state machine that controls the editing
    // cycle of the record while it is being loaded, changed, updated, or
    // cancelled. The useState hook ensures that the environment gets
    // re-configured each time the state changes.
    //
    const [editingState, setEditingState] = useState(editingStates.LOADING);
    useEffect(() => {
        switch (editingState) {
            case editingStates.LOADING:
                getConfiguration();
                break;

            case editingStates.EDITING:
                break;

            case editingStates.CANCELLING:
                if (isChanged()) {
                    showYesNoDialog(dialogs.SHOW_DIALOG, dialogs.YES);
                } else {
                    setEditingState(editingStates.EXITING);
                }
                break;

            case editingStates.EXITING:
                return navigate("/MainPage");

            case editingStates.UPDATING:
                if (validate()) {
                    updateConfiguration();
                    return navigate("/MainPage");
                } else {
                    setEditingState(editingStates.EDITING);
                }
                break;

            case editingStates.ERROR:
                // This user may not be authorised to access this data
                setEditingState(editingStates.EXITING);
                break;

            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editingState]);

    //
    // validate()
    // ==========
    // Validates each field, returning an updated errors object. This
    // contains properties for each of the error messages that are to
    // be displayed.
    //
    function validate() {
        let errors = {};
        let valid = true;
        const msg = "An upper and a lower age range must be specified";

        if (Lw_Sub_Juvenile_Age <= 0 || Up_Sub_Juvenile_Age <= Lw_Sub_Juvenile_Age ) {
            errors.Sub_Juvenile_Age = msg;
            valid = false;
        }

        if (Lw_Juvenile_Age <= 0 || Up_Juvenile_Age <= Lw_Juvenile_Age) {
            errors.Juvenile_Age = msg;
            valid = false;
        }

        if (Lw_Junior_Age <= 0 || Up_Junior_Age <= Lw_Junior_Age) {
            errors.Junior_Age = msg;
            valid = false;
        }

        if (Lw_Youth_Age <= 0 || Up_Youth_Age <=  Lw_Youth_Age) {
            errors.Youth_Age = msg;
            valid = false;
        }

        if (Lw_Adult_Age <= 0 || Up_Adult_Age <= Lw_Adult_Age) {
            errors.Adult_Age = msg;
            valid = false;
        }

        if (Lw_Masters_1_Age <= 0 || Up_Masters_1_Age <= Lw_Masters_1_Age) {
            errors.Masters_1_Age = msg;
            valid = false;
        }

        if (Lw_Masters_2_Age <= 0 || Up_Masters_2_Age <= Lw_Masters_2_Age) {
            errors.Masters_2_Age = msg;
            valid = false;
        }

        if (Lw_Masters_3_Age <= 0 || Up_Masters_3_Age <= Lw_Masters_3_Age) {
            errors.Masters_3_Age = msg;
            valid = false;
        }

        if (Lw_Masters_4_Age <= 0 || Up_Masters_4_Age <= Lw_Masters_4_Age) {
            errors.Masters_4_Age = msg;
            valid = false;
        }

        if (Lw_Masters_5_Age <= 0 || Up_Masters_5_Age <= Lw_Masters_5_Age) {
            errors.Masters_5_Age = msg;
            valid = false;
        }

        setErrors(errors);
        return valid
    }

    //
    // getConfiguration()
    // ==================
    const getConfiguration = async () => {
        try {
            let response = await axios.get(baseURL + "getConfiguration?JWT=" + JWT);
            if (response.status === 200) {
                // The configuration exists, so it can be edited.
                setSavedData(response.data);
                setLw_Sub_Juvenile_Age(response.data.lw_sub_juvenile_age);
                setUp_Sub_Juvenile_Age(response.data.up_sub_juvenile_age);
                setLw_Juvenile_Age(response.data.lw_juvenile_age);
                setUp_Juvenile_Age(response.data.up_juvenile_age);
                setLw_Junior_Age(response.data.lw_junior_age);
                setUp_Junior_Age(response.data.up_junior_age);
                setLw_Youth_Age(response.data.lw_youth_age);
                setUp_Youth_Age(response.data.up_youth_age);
                setLw_Adult_Age(response.data.lw_adult_age);
                setUp_Adult_Age(response.data.up_adult_age);
                setLw_Masters_1_Age(response.data.lw_masters_1_age);
                setUp_Masters_1_Age(response.data.up_masters_1_age);
                setLw_Masters_2_Age(response.data.lw_masters_2_age);
                setUp_Masters_2_Age(response.data.up_masters_2_age);
                setLw_Masters_3_Age(response.data.lw_masters_3_age);
                setUp_Masters_3_Age(response.data.up_masters_3_age);
                setLw_Masters_4_Age(response.data.lw_masters_4_age);
                setUp_Masters_4_Age(response.data.up_masters_4_age);
                setLw_Masters_5_Age(response.data.lw_masters_5_age);
                setUp_Masters_5_Age(response.data.up_masters_5_age);
                setEditingState(editingStates.EDITING);
            } else if (response.status === 404) {
                setEditingState(editingStates.NOT_FOUND);
            }
        } catch (err) {
            console.log("getConfiguration error:" + err.status + " "+ err.message);
            setEditingState(editingStates.ERROR);
        }
    };

    //
    // updateConfiguration()
    // =====================
    const updateConfiguration = () => {
        try {
            axios.put(baseURL + "updateConfiguration?JWT=" + JWT, {
                config_ID: 1,
                lw_sub_juvenile_age: Lw_Sub_Juvenile_Age,
                up_sub_juvenile_age: Up_Sub_Juvenile_Age,
                lw_juvenile_age: Lw_Juvenile_Age,
                up_juvenile_age: Up_Juvenile_Age,
                lw_junior_age: Lw_Junior_Age,
                up_junior_age: Up_Junior_Age,
                lw_youth_age: Lw_Youth_Age,
                up_youth_age: Up_Youth_Age,
                lw_adult_age: Lw_Adult_Age,
                up_adult_age: Up_Adult_Age,
                lw_masters_1_age: Lw_Masters_1_Age,
                up_masters_1_age: Up_Masters_1_Age,
                lw_masters_2_age: Lw_Masters_2_Age,
                up_masters_2_age: Up_Masters_2_Age,
                lw_masters_3_age: Lw_Masters_3_Age,
                up_masters_3_age: Up_Masters_3_Age,
                lw_masters_4_age: Lw_Masters_4_Age,
                up_masters_4_age: Up_Masters_4_Age,
                lw_masters_5_age: Lw_Masters_5_Age,
                up_masters_5_age: Up_Masters_5_Age
            }).then((response) => {
            });
        } catch (err) {
            console.log("updateConfiguration() err: ");
            console.log(err.message);
        }
    };

    //
    // showYesNoDialog()
    // =================
    // In React, a dialog box is either a simple JavaScript alert() or a custom-designed
    // box. This function interacts with the Modal Dialog Box React and Bulma code in the
    // main routine. The dialog box is made visible by calling this function with param set
    // true. The dialog is hidden when the function is called with a param set to false.
    //
    function showYesNoDialog(mode, response) {
        const modal = document.querySelector("#modalYesNo");
        if (mode === dialogs.SHOW_DIALOG) {
            // Make the modal dialog visible by adding the style property.
            modal.classList.add('is-active');
        } else if (mode === dialogs.HIDE_DIALOG) {
            // Hide the modal dialog by removing the style property.
            modal.classList.remove('is-active');
            if (response === dialogs.YES) {
                setEditingState(editingStates.EXITING);
            } else if (response === dialogs.NO) {
                setEditingState(editingStates.EDITING);
            }
        }
    }

    //
    // YesNoDialog()
    // =============
    // Modal dialog for confirming cancellation of changes made. Note the way this
    // is packaged as a React Fragment. This allows it to be referenced in the main
    // page code rather than having to be coded in-line. This simplifies the overall
    // page layout. It is called by placing this element in-line on the main page:
    //
    //       <YesNoDialog />
    //
    const YesNoDialog = () => {
        return (
            <React.Fragment>
                <div className="container is-centered">
                    <div className="column">
                        <div className="modal" id="modalYesNo" >
                            <div className="box has-background-blue"
                                style={{ width: "310px" }}>
                                <p style={{ fontSize: "20pt" }}>Settings have changed</p>
                                <br></br>
                                <p>Do you really want to cancel these changes?</p>
                                <br></br>
                                <p>Click <b>Yes</b> to cancel these changes or</p>
                                <br></br>
                                <p>Click <b>No</b> to continue editing.</p>
                                <br></br>

                                <div className="field is-grouped is-centered">
                                    <div className="field pl-5 pr-4">
                                        <button className="button is-danger"
                                            id="modalYes"
                                            style={{ width: "100px" }}
                                            onClick={e => showYesNoDialog(dialogs.HIDE_DIALOG, dialogs.YES)} >
                                            Yes
                                        </button>
                                    </div>

                                    <div className="field">
                                        <button className="button is-danger"
                                            id="modalNo"
                                            style={{ width: "100px" }}
                                            onClick={e => showYesNoDialog(dialogs.HIDE_DIALOG, dialogs.NO)} >
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    //
    // ManageConfiguration()
    // =====================
    // This renders and returns the main page. All of the referenced functions have already
    // been declared by this stage.
    //
    return (
        <section className="hero is-primary is-fullheight has-background-grey-lighter">
            <PageHeader />
            <div className="container is-centered">
                <section className="has-background-light box"
                    style={{ width: "800px" }} >
                    <p className="is-size-3 mx-6 mt-0">
                        Settings
                    </p>
                    <br></br>

                    <YesNoDialog />

                    <div className="label is-size-5 mx-6 mt-0">
                        Dancer age ranges
                    </div>
                    <br></br>

                    <div className="columns">
                        {/* LEFT-HAND COLUMN */}
                        <div className="column is-10 mx-6 mt-0">
                            <div className="field is-grouped">
                                <div className="label"
                                    style={{ width: "125px", height: "20px", alignItems: "center" }}>
                                    Sub-Juvenile
                                </div>

                                <div className="field">
                                    <input className="input"
                                        id="Lw_Sub_Juvenile_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "20px" }}
                                        value={Lw_Sub_Juvenile_Age}
                                        onChange={e => setLw_Sub_Juvenile_Age(e.target.value)}
                                    />
                                </div>

                                <div className="field">
                                    <input className="input"
                                        id="Up_Sub_Juvenile_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "10px" }}
                                        value={Up_Sub_Juvenile_Age}
                                        onChange={e => setUp_Sub_Juvenile_Age(e.target.value)}
                                    />
                                </div>
                                <p className="help is-danger is-size-6 mt-0">&nbsp;{errors.Sub_Juvenile_Age}</p>
                            </div>

                            <div className="field is-grouped">
                                <div className="label"
                                    style={{ width: "125px", height: "30px", alignItems: "center" }}>
                                    Juvenile
                                </div>

                                <div className="field">
                                    <input className="input"
                                        id="Lw_Juvenile_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "20px" }}
                                        value={Lw_Juvenile_Age}
                                        onChange={e => setLw_Juvenile_Age(e.target.value)}
                                    />
                                </div>

                                <div className="field">
                                    <input className="input"
                                        id="Up_Juvenile_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "10px"}}
                                        value={Up_Juvenile_Age}
                                        onChange={e => setUp_Juvenile_Age(e.target.value)}
                                    />
                                </div>
                                <p className="help is-danger is-size-6 mt-0">&nbsp;{errors.Juvenile_Age}</p>
                            </div>

                            <div className="field is-grouped">
                                <div className="label"
                                    style={{ width: "125px", height: "30px", alignItems: "center" }}>
                                    Junior
                                </div>
                                <div className="field">
                                    <input className="input"
                                        id="Lw_Junior_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "20px" }}
                                        value={Lw_Junior_Age}
                                        onChange={e => setLw_Junior_Age(e.target.value)}
                                    />
                                </div>

                                <div className="field">
                                    <input className="input"
                                        id="Up_Junior_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "10px" }}
                                        value={Up_Junior_Age}
                                        onChange={e => setUp_Junior_Age(e.target.value)}
                                    />
                                </div>
                                <p className="help is-danger is-size-6 mt-0">&nbsp;{errors.Junior_Age}</p>
                            </div>

                            <div className="field is-grouped">
                                <div className="label"
                                    style={{ width: "125px", height: "30px", alignItems: "center" }}>
                                    Youth
                                </div>
                                <div className="field">
                                    <input className="input"
                                        id="Lw_Youth_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "20px" }}
                                        value={Lw_Youth_Age}
                                        onChange={e => setLw_Youth_Age(e.target.value)}
                                    />
                                </div>

                                <div className="field">
                                    <input className="input"
                                        id="Up_Youth_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "10px" }}
                                        value={Up_Youth_Age}
                                        onChange={e => setUp_Youth_Age(e.target.value)}
                                    />
                                </div>
                                <p className="help is-danger is-size-6 mt-0">&nbsp;{errors.Youth_Age}</p>
                            </div>

                            <div className="field is-grouped">
                                <div className="label"
                                    style={{ width: "125px", height: "30px", alignItems: "center" }}>
                                    Adult
                                </div>
                                <div className="field">
                                    <input className="input"
                                        id="Lw_Adult_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "20px" }}
                                        value={Lw_Adult_Age}
                                        onChange={e => setLw_Adult_Age(e.target.value)}
                                    />
                                </div>

                                <div className="field">
                                    <input className="input"
                                        id="Up_Adult_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "10px" }}
                                        value={Up_Adult_Age}
                                        onChange={e => setUp_Adult_Age(e.target.value)}
                                    />
                                </div>
                                <p className="help is-danger is-size-6 mt-0">&nbsp;{errors.Adult_Age}</p>
                            </div>

                            <div className="field is-grouped">
                                <div className="label"
                                    style={{ width: "125px", height: "30px", alignItems: "center" }}>
                                    Masters 1
                                </div>
                                <div className="field">
                                    <input className="input"
                                        id="Lw_Masters_1_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "20px" }}
                                        value={Lw_Masters_1_Age}
                                        onChange={e => setLw_Masters_1_Age(e.target.value)}
                                    />
                                </div>

                                <div className="field">
                                    <input className="input"
                                        id="Up_Masters_1_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "10px" }}
                                        value={Up_Masters_1_Age}
                                        onChange={e => setUp_Masters_1_Age(e.target.value)}
                                    />
                                </div>
                                <p className="help is-danger is-size-6 mt-0">&nbsp;{errors.Masters_1_Age}</p>
                            </div>

                            <div className="field is-grouped">
                                <div className="label"
                                    style={{ width: "125px", height: "30px", alignItems: "center" }}>
                                    Masters 2
                                </div>
                                <div className="field">
                                    <input className="input"
                                        id="Lw_Masters_2_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "20px" }}
                                        value={Lw_Masters_2_Age}
                                        onChange={e => setLw_Masters_2_Age(e.target.value)}
                                    />
                                </div>

                                <div className="field">
                                    <input className="input"
                                        id="Up_Masters_2_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "10px" }}
                                        value={Up_Masters_2_Age}
                                        onChange={e => setUp_Masters_2_Age(e.target.value)}
                                    />
                                </div>
                                <p className="help is-danger is-size-6 mt-0">&nbsp;{errors.Masters_2_Age}</p>
                            </div>

                            <div className="field is-grouped">
                                <div className="label"
                                    style={{ width: "125px", height: "30px", alignItems: "center" }}>
                                    Masters 3
                                </div>
                                <div className="field">
                                    <input className="input"
                                        id="Lw_Masters_3_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "20px"}}
                                        value={Lw_Masters_3_Age}
                                        onChange={e => setLw_Masters_3_Age(e.target.value)}
                                    />
                                </div>

                                <div className="field">
                                    <input className="input"
                                        id="Up_Masters_3_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "10px" }}
                                        value={Up_Masters_3_Age}
                                        onChange={e => setUp_Masters_3_Age(e.target.value)}
                                    />
                                </div>
                                <p className="help is-danger is-size-6 mt-0">&nbsp;{errors.Masters_3_Age}</p>
                            </div>

                            <div className="field is-grouped">
                                <div className="label"
                                    style={{ width: "125px", height: "30px", alignItems: "center" }}>
                                    Masters 4
                                </div>
                                <div className="field">
                                    <input className="input"
                                        id="Lw_Masters_4_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "20px" }}
                                        value={Lw_Masters_4_Age}
                                        onChange={e => setLw_Masters_4_Age(e.target.value)}
                                    />
                                </div>

                                <div className="field">
                                    <input className="input"
                                        id="Up_Masters_4_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "10px"}}
                                        value={Up_Masters_4_Age}
                                        onChange={e => setUp_Masters_4_Age(e.target.value)}
                                    />
                                </div>
                                <p className="help is-danger is-size-6 mt-0">&nbsp;{errors.Masters_4_Age}</p>
                            </div>

                            <div className="field is-grouped">
                                <div className="label"
                                    style={{ width: "125px", height: "30px", alignItems: "center" }}>
                                    Masters 5
                                </div>
                                <div className="field">
                                    <input className="input"
                                        id="Lw_Masters_5_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "20px" }}
                                        value={Lw_Masters_5_Age}
                                        onChange={e => setLw_Masters_5_Age(e.target.value)}
                                    />
                                </div>

                                <div className="field">
                                    <input className="input"
                                        id="Up_Masters_5_Age"
                                        type="number"
                                        style={{ width: "50px", height: "30px", marginRight: "10px" }}
                                        value={Up_Masters_5_Age}
                                        onChange={e => setUp_Masters_5_Age(e.target.value)}
                                    />
                                </div>
                                <p className="help is-danger is-size-6 mt-0">&nbsp;{errors.Masters_5_Age}</p>
                            </div>
                        </div>
                    </div>

                    <div className="field is-grouped mx-6">
                        <div className="field pr-3">
                            <button className="button is-success"
                                type="submit"
                                id="Update"
                                style={{ width: "100px" }}
                                onClick={e => setEditingState(editingStates.UPDATING)} >
                                Update
                            </button>
                        </div>

                        <div className="field">
                            <button className="button is-success"
                                id="Cancel"
                                style={{ width: "100px" }}
                                onClick={() => { setEditingState(editingStates.CANCELLING) }}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
}
