//
// CHANGE REGISTRANTS PASSWORD
// ===========================
// This module allows a user logged into the administrator section
// of Dancesport to change their password.
//
//
// Revision History
// ================
// 01.09.2022 BRD Original version.
//
import React from "react"
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBaseURL } from './getBaseURL';
import Axios from 'axios';

import "bulma/css/bulma.css";
import "./Dancesport.css";
import eye from "./graphics/password_eye.gif";
import { PageHeader } from "./PageHeader";
import { editingStates } from "./Constants";

const baseURL = getBaseURL();
const MIN_PASSWORD_LENGTH = 6;

export function ChangePassword() {
    const [errors, setErrors] = useState([]);
    const axios = Axios;
    const navigate = useNavigate();
    const JWT = sessionStorage.getItem('JWT');

    const [UserAuthority, setUserAuthority] = useState("");
    const [PasswordStatus, setPasswordStatus] = useState("");

    const [CurrentPassword, setCurrentPassword] = useState("");
    const [CurrentPasswordVisibility, setCurrentPasswordVisibility] = useState("password");
    const [NewPassword, setNewPassword] = useState("");
    const [NewPasswordVisibility, setNewPasswordVisibility] = useState("password");
    const [ConfirmedNewPassword, setConfirmedNewPassword] = useState("");
    const [ConfirmedNewPasswordVisibility, setConfirmedNewPasswordVisibility] = useState("password");

    //
    // Authenticate user
    // =================
    // Checks to see if the local storage has a UserID value set to ensure
    // that only authenticated users can access this page. Users who are not
    // authenticated are directed back to the Sign-on page.
    //
    const UserID = sessionStorage.getItem('userID');
    useEffect(() => {
        if (!UserID) {
            return navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [UserID]);

    //
    // Editing state control
    // =====================
    // This section defines the state machine that controls the password
    // change process. The useState Hook ensures that the environment gets
    // re-configured each time the state changes.
    //
    const [editingState, setEditingState] = useState(editingStates.LOADING);
    useEffect(() => {
        var errors = {};
        switch (editingState) {
            case editingStates.LOADING:
                // This is the initial stage that allows the user to enter their
                // original and changed passwords.
                //console.log("LOADING");
                break;

            case editingStates.AUTHENTICATING:
                // Validates the information entered before trying to
                // authenticate the current password of the registant.
                //console.log("AUTHENTICATING ...");
                authenticateUser(UserID, CurrentPassword);
                break;

            case editingStates.NOT_AUTHENTICATED:
                //console.log("Not authenticated ...");
                // Reset the editing state so that the registrant can try
                // again or exit
                errors.CurrentPassword = "The password entered is not your current password";
                setErrors(errors);
                setEditingState(editingStates.LOADING);
                break;

            case editingStates.AUTHENTICATED:
                // The password entered matches the registrants current password
                // and the new password has passed validation. Update the
                // registrant record with the changed password. Note that this
                // also resets their password status to show that they have
                // a valid and active password. This works in conjunction with the
                // Forgot Password and new registrant processes.
                let error = false;

//
                // Password validation code example
                // ================================
                // if (!Password) {
                //     errors.Passwordmsg = "A password must be entered";
                // } else if (Password.length < 5) {
                //     errors.Passwordmsg = "The password must be at least five characters long.";
                // } else if (!/\d/.test(Password)) {
                //     errors.Passwordmsg = "The password must contain at least one number.";
                // } else if (!/[!@#$%&?]/g.test(Password)) {
                //     errors.Passwordmsg = "The password must contain at least one special character";
                // } else if (!/[A-Z]/g.test(Password)) {
                //     errors.Passwordmsg = "The password must contain at least one capital letter";
                // }

                setNewPassword(NewPassword.trim());
                if (NewPassword === "") {
                    errors.NewPassword = "Your new password must be entered."
                    error = true;
                } else if (NewPassword.length < MIN_PASSWORD_LENGTH) {
                    errors.NewPassword = "Your new password must a minimum of six characters long."
                    error = true;
                } else if (!/\d/.test(NewPassword)) {
                    errors.NewPassword = "Your new password must contain at least one digit."
                    error = true;
                } else if (!/[!@#$%&?]/g.test(NewPassword)) {
                    errors.NewPassword = "The password must contain at least one of these characters: !@#$%&?";
                    error = true;
                }

                if ((ConfirmedNewPassword.trim() === "") ||
                     (NewPassword !== ConfirmedNewPassword)) {
                    errors.ConfirmedNewPassword = "Your new passwords do not match."
                    error = true;
                }
                setErrors(errors);
                //console.log("validating: " + error);
                if (error) {
                    setEditingState(editingStates.LOADING);
                } else {
                    //console.log("Authenticated and ready to update and exit...");
                    setEditingState(editingStates.CHANGING_PASSWORD);
                }
                break;

            case editingStates.CHANGING_PASSWORD:
                //console.log("CHANGING_PASSWORD ...");
                setUserAuthority("A");
                setPasswordStatus("A");
                updatePassword();
                //setEditingState(editingStates.EXITING);
                break;

            case editingStates.ERROR:
                // The password update failed.
                //console.log("ERROR ...");
                break;

            case editingStates.EXITING:
                //console.log("EXITING ...");
                return navigate("/MainPage");

            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editingState]);

    //
    // authenticateUser()
    // ==================
    // Authenticates the user by verifying that their registration ID number or their email address can be
    // located by the back-end and that their password matches the encrypted one stored in the database.
    //
    // If the user is authenticated, a JSON Web Token (JWT) is returned from the back-end. This is saved to
    // the session storage so that it can be used to authorise all subsequent data requests from the back
    // end.
    //
    const authenticateUser = async (UserID, Password) => {
        //console.log("authenticateUser " + UserID + " " + Password);
        try {
            let response = await axios.get(baseURL + "authenticateUser?user_ID=" + encodeURIComponent(UserID) +
                "&password=" + encodeURIComponent(Password));
            if (response.status === 200) {
                // The user was found and their credentials were authenticated.
                //console.log("\nauthenticate(): " + response.status + "\n");
                setEditingState(editingStates.AUTHENTICATED);
            } else {
                //console.log("\nauthenticate() " + response.status + ". Could not authenticate user");
                setEditingState(editingStates.NOT_AUTHENTICATED);
            }
        } catch (err) {
            //console.log("\nauthenticate() error " + err.status);
            setEditingState(editingStates.NOT_AUTHENTICATED);
        }
    }

    //
    // updatePassword()
    // ================
    // Based on the current editingState, the correct route to update an existing
    // registrant or insert a new one is called.
    //
    const updatePassword = () => {
        try {
            axios.put(baseURL + "changeAdminUserPassword?JWT=" + JWT, {
                id: UserID,
                user_authority: UserAuthority,
                password: NewPassword,
                password_status: PasswordStatus
            }).then((response) => {
                // The password was changed successfully.
                setEditingState(editingStates.EXITING);
            });
        } catch (err) {
            // The password was not changed.
            setEditingState(editingStates.ERROR);
            console.log("updatePassword() returned an error: ");
            console.log(err.message);
        }
    }

    //
    // ChangePassword()
    // ================
    // This renders and returns the password change page. All of the referenced functions have already
    // been declared by this stage.
    //
    return (
        <section className="hero is-fullheight has-background-grey-lighter">
            <PageHeader />
            <div className="container is-vcentered">
                <section className="box has-background-light is-centered"
                    style={{position: "fixed", top: "30%", left: "40%"}} >
                    <p style={{ fontSize: "20pt" }}>Change My Password</p>
                    <br></br>

                    <div className="field">
                        <label className="label">
                            Current password
                        </label>
                    </div>

                    <div className="field is-grouped">
                        <div className="field">
                            <div className="control has-icons-left">
                                <input className="input"
                                    id="CurrentPassword"
                                    style={{ width: "400px" }}
                                    type={CurrentPasswordVisibility}
                                    placeholder="********"
                                    autoComplete="off"
                                    maxLength={20}
                                    value={CurrentPassword}
                                    onChange={e => setCurrentPassword(e.target.value)}
                                />
                                <span className="icon is-small is-left">
                                    <i className="fa fa-lock" />
                                </span>
                                <p className="help is-danger is-size-6">{errors.CurrentPassword}&nbsp;</p>
                            </div>
                        </div>

                        <img src={eye}
                            style={{ width: "25px", height: "20px", marginLeft: "10px", marginTop: "10px" }}
                            alt="password reveal"
                            onClick={e => {
                                if (CurrentPasswordVisibility === "password") {
                                    setCurrentPasswordVisibility("text");
                                } else {
                                    setCurrentPasswordVisibility("password");
                                }
                            }}
                        />
                    </div>


                    <div className="field">
                        <label className="label">
                            New password
                        </label>
                    </div>

                    <div className="field is-grouped">
                        <div className="field">
                            <div className="control has-icons-left">
                                <input className="input"
                                    id="NewPassword"
                                    style={{ width: "400px" }}
                                    type={NewPasswordVisibility}
                                    placeholder="********"
                                    autoComplete="off"
                                    maxLength={20}
                                    value={NewPassword}
                                    onChange={e => setNewPassword(e.target.value)}
                                />
                                <span className="icon is-small is-left">
                                    <i className="fa fa-lock" />
                                </span>
                                <p className="help is-danger is-size-6">{errors.NewPassword}&nbsp;</p>
                            </div>

                        </div>

                        <img src={eye}
                            style={{ width: "25px", height: "20px", marginLeft: "10px", marginTop: "10px" }}
                            alt="password reveal"
                            onClick={e => {
                                if (NewPasswordVisibility === "password") {
                                    setNewPasswordVisibility("text");
                                } else {
                                    setNewPasswordVisibility("password");
                                }
                            }}
                        />
                    </div>

                    <label className="label">
                        Reenter your new password
                    </label>

                    <div className="field is-grouped">
                        <div className="field">
                            <div className="control has-icons-left">
                                <input className="input"
                                    style={{ width: "400px" }}
                                    id="ConfirmedNewPassword"
                                    type={ConfirmedNewPasswordVisibility}
                                    placeholder="********"
                                    autoComplete="off"
                                    maxLength={20}
                                    value={ConfirmedNewPassword}
                                    onChange={e => setConfirmedNewPassword(e.target.value)}
                                />
                                <span className="icon is-small is-left">
                                    <i className="fa fa-lock" />
                                </span>
                                <p className="help is-danger is-size-6">{errors.ConfirmedNewPassword}&nbsp;</p>
                            </div>
                        </div>

                        <img src={eye}
                            style={{ width: "25px", height: "20px", marginLeft: "10px", marginTop: "10px" }}
                            alt="password reveal"
                            onClick={e => {
                                if (ConfirmedNewPasswordVisibility === "password") {
                                    setConfirmedNewPasswordVisibility("text");
                                } else {
                                    setConfirmedNewPasswordVisibility("password");
                                }
                            }}
                        />
                    </div>

                    <div className="field is-grouped">
                        <div className="field pr-3">
                            <button className="button is-success"
                                id="Change password"
                                style={{ width: "100px" }}
                                onClick={e => {
                                    setEditingState(editingStates.AUTHENTICATING);
                                }}>
                                Change
                            </button>
                        </div>

                        <div className="field">
                            <button className="button is-success"
                                id="Cancel"
                                style={{ width: "100px" }}
                                onClick={e => { setEditingState(editingStates.EXITING); }}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
}
