//
// MAIN DANCESPORT NAVIGATION PAGE
// ===============================
// This module links all the Dancesport functionality together from a single page.
// This page is only accessible by administrators.
//
// Revision History
// ================
// 04.09.2022 BRD 1.1 Original version
//
import React from "react"
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "bulma/css/bulma.css";
import { PageHeader } from "./PageHeader";
import ManageRegistrants from "./graphics/ManageRegistrants.JPG";
import ManageConfiguration from "./graphics/ManageConfiguration.JPG";
import ManageAdministrators from "./graphics/ManageAdministrators.JPG";
import ChangeMyPassword from "./graphics/ChangeMyPassword.JPG";

import About from "./graphics/About.JPG"
import Unused from "./graphics/UnusedMenuItem.JPG";
import { useState } from 'react';

//
// MainPage()
// ==========
export function MainPage() {
    let navigate = useNavigate();
    //document.addEventListener('keydown', keyListener);

    // Retrieve the ID of the user who is logged in from the local
    // storage.
    const [UserID, setUserID] = useState(sessionStorage.getItem('userID'));
    const [route, setRoute] = useState("");
    //console.log("JWT : " + sessionStorage.getItem('JWT'));

    //
    // Authentication and Navigation
    // =============================
    // Checks to see if the local storage has a userID set to ensure that
    // only authenticated uses can navigate around the application. This
    // is also a convenient way of logging out. When the UserID is set to
    // blank via the Sign-out button click, there is no longer an
    // authenticated user.
    //
    useEffect(() => {
        if (!UserID) {
            sessionStorage.setItem("userID", "");
            //document.removeEventListener('keydown', keyListener);
            return navigate("/");
        } else {
            switch (route) {
                case "ManageRegistrants":
                    //document.removeEventListener('keydown', keyListener);
                    return navigate("/ManageRegistrants");

                case "ManageConfiguration":
                    //document.removeEventListener('keydown', keyListener);
                    return navigate("/ManageConfiguration");

               case "ManageAdministrators":
                     // RA_BRD Disabled for 1.04 release
                    //return navigate("/ManageAdministrators");
                    break;

                case "ChangePassword":
                    return navigate("/ChangePassword");

                case "About":
                    //document.removeEventListener('keydown', keyListener);
                    return navigate("/About");

                default:
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [UserID, route]);

    //
    // keyListener()
    // =============
    // Sets a document key listener to detect the Enter key being pressed.
    // This is used to trigger the default submission behaviour for a
    // page whose elements are not wrapped in a form.
    //
    // function keyListener(event) {
    //     if (event.defaultPrevented) {
    //         return;
    //     }
    //     var key = event.key || event.keyCode;
    //     if (key === 'Enter' || key === 'Esc' || key === 27) {
    //         event.preventDefault()
    //         try {
    //             document.getElementById("SignOut").click();
    //         } catch {
    //         }
    //     }
    // }

    return (
        <section className="hero is-primary is-fullheight has-background-grey-lighter">
            <PageHeader />
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-size-5 mx-6 mb-6 mt-0" >
                        {/* LEFT-HAND COLUMN */}
                        <div className="column">
                            <img
                                src={ManageRegistrants}
                                alt="Manage Registrant Information"
                                onClick={() => setRoute("ManageRegistrants")}
                            />
                            <br />
                            <br />

                            <img
                                src={ManageConfiguration}
                                alt="Manage Dancesport Configuration"
                                onClick={() => setRoute("ManageConfiguration")}
                            />
                            <br />
                            <br />

                            <div className="field mx-2 mt-0">
                                <button
                                    id="SignOut"
                                    className="button is-success"
                                    style={{ width: "100px" }}
                                    onClick={e => setUserID("")}
                                >
                                    Sign-out
                                </button>
                            </div>
                        </div>

                        {/* MIDDLE COLUMN */}
                        <div className="column">
                            <img
                                src={ManageAdministrators}
                                alt="Manage Administrators"
                                onClick={() => setRoute("ManageAdministrators")}
                            />
                            <br />
                            <br />

                            <img
                                src={ChangeMyPassword}
                                alt="Change My Password"
                                onClick={() => setRoute("ChangePassword")}
                            />
                            <br />
                            <br />
                        </div>

                        {/* RIGHT-HAND COLUMN */}
                        <div className="column">
                            <img
                                src={Unused}
                                alt=""
                            />
                            <br />
                            <br />

                            <img
                                src={About}
                                alt="About"
                                onClick={e => setRoute("About")}
                            />
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
