//
// ABOUT DANCESPORT
// ================
// This module provides information about the Dancesport application
//
// Documentation
// =============
//
// Revision History
// ================
// 01.09.2022 BRD Original version.
// 28.05.2023 BRD Retrieve the version number dynamically from the session environment.
//
import React from "react"
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import "bulma/css/bulma.css";
import "./Dancesport.css";
import {PageHeader} from "./PageHeader";

//
// About()
// =======
export function About() {
    const navigate = useNavigate();
    const version = sessionStorage.getItem('version');

    document.addEventListener('keydown', keyListener);

    // Retrieve the ID of the user who is logged in from the local storage.
    const UserID = sessionStorage.getItem('userID');
    const [exiting, setExiting] = useState(false);

    //
    // Authentication and Navigation
    // =============================
    // Checks to see if the local storage has a userID set to ensure that
    // only authenticated uses can access this page. This useState also
    // monitors the exiting variable which is set by the Close button
    // to navigate back to the main menu.
    //
    useEffect(() => {
        if (!UserID) {
            // This page was not loaded with an authenticated user
            // so navigate back to the signin page.
            return navigate("/");
        } else if (exiting) {
            document.removeEventListener('keydown', keyListener);
            return navigate("/MainPage");
        }
    },[UserID, exiting]);

    //
    // keyListener()
    // =============
    // Sets a document key listener to detect the Enter key being pressed.
    // This is used to trigger the default submission behaviour for a
    // page whose elements are not wrapped in a form.
    //
    function keyListener(event) {
        var key = event.key || event.keyCode;
        if (key === 'Enter' || key === 'Esc' || key === 27) {
            event.stopPropagation();
            try {
             document.getElementById("Close").click();
            } catch {
            }
        }
    }

    //
    // Display page
    // ============
    return (
        <section className="hero is-primary is-fullheight has-background-grey-lighter">
           <PageHeader />
           <div className="hero-body">
                <div className="container">
                   <div className = "columns is-centered">
                        <div className="column">
                        </div>

                        <div className="column is-primary" >
                            <div className="hero-body" >
                                <div className="container">
                                    <section className="has-background-light box"
                                        style = {{width: "800px"}} >
                                        <p className="is-size-3 mx-6 mt-0">
                                           About Dancesport
                                        </p>
                                        <br></br>

                                        <div className = "columns" >
                                            {/* LEFT-HAND COLUMN */}
                                            <div className = "column">
                                                <div className = "label is-size-6 mx-6 mt-0">
                                                    <label className = "label"
                                                           style = {{ width: "100px", height: "125px"}}>
                                                           About
                                                    </label>
                                                </div>

                                                <br></br>

                                                <div className = "label is-size-6 mx-6 mt-0">
                                                    <label className = "label"
                                                           style = {{ width: "100px", height: "28px"}}>
                                                           Version
                                                    </label>
                                                </div>

                                                <br></br>

                                                <div className = "label is-size-6 mx-6 mt-0">
                                                    <label className = "label"
                                                           style = {{ width: "100px", height: "105px"}}>
                                                           Design and development
                                                    </label>
                                                </div>

                                                <div className = "label is-size-6 mx-6">
                                                    <label className = "label"
                                                           style = {{ width: "70px", marginTop: "45px" }}>
                                                           Warranty
                                                    </label>
                                                </div>
                                            </div>

                                            {/* RIGHT-HAND COLUMN */}
                                            <div className = "column">
                                                <div className = "label is-size-6 mx-6 mt-0">
                                                    <label className = "label"
                                                           style = {{ width: "460px", fontWeight: "normal"}}>
                                                           The Dancesport System manages the information about our registrants,
                                                           both professional and amateur, including their contact details,
                                                           qualifications, and their achievements.
                                                    </label>
                                                </div>

                                                <div className = "label is-size-6 mx-6 mt-0">
                                                    <label className = "label"
                                                           style = {{ width: "460px", fontWeight: "normal" }}>
                                                           (c) New Zealand Ballroom Dance Council, a registered charity, 2020-2023 - All Rights Reserved.
                                                    </label>
                                                </div>

                                                <br></br>

                                                <div className = "label is-size-6 mx-6 mt-0">
                                                    <label className = "label"
                                                           style = {{ width: "460px", fontWeight: "normal" }}>
                                                           {version}
                                                    </label>
                                                </div>

                                                <br></br>

                                                <div className = "label is-size-6 mx-6 mt-0">
                                                    <label className = "label"
                                                           style = {{ width: "150px", fontWeight: "normal" }}>
                                                           Robert Connors
                                                    </label>
                                                    <label className = "label"
                                                           style = {{ width: "150px", fontWeight: "normal" }}>
                                                           Hossein Jahedi
                                                    </label>
                                                    <label className = "label"
                                                           style = {{ width: "150px", fontWeight: "normal" }}>
                                                           Jane Dowdeswell
                                                    </label>
                                                    <label className = "label"
                                                           style = {{ width: "150px", fontWeight: "normal" }}>
                                                           Badger Dowdeswell
                                                    </label>

                                                    <div className = "label is-size-6 mx-1">
                                                    <label className = "label"
                                                           style = {{ width: "500px", marginTop: "30px", fontWeight: "normal"}}>
                                                           The software is provided "as is", without warranty of any kind, express or
                                                           implied, including but not limited to the warranties of fitness for a
                                                           particular purpose, merchantability, and noninfringement. In no event shall
                                                           the authors or copyright holders be liable for any claim, damages or other
                                                           liability, whether in an action of contract, tort, or otherwise, arising from,
                                                           out of or in connection with the software or the use or other dealings in the
                                                           software.
                                                    </label>
                                                </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="field">
                                                <button
                                                    id="Close"
                                                    className="button is-success mx-6 mt-0"
                                                    style = {{ width: "100px" }}
                                                    onClick = {e => setExiting(true)} >
                                                    Close
                                                </button>
                                            </div>
                                    </section>
                                </div>
                            </div>
                        </div>

                        <div className="column">
                        </div>
                   </div>
                </div>
            </div>
        </section>
    );
}
