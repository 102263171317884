//
// DANCESPORT SIGN-IN
// ==================
// Manages the user sign-in functionality, validating the user and their
// password against an existing record in the database. If a user
// can be authenticated, the page navigates to the correct home
// page to allow other pages to be displayed.
//
// Revision History
// ================
// 18.04.2022 BRD Original version.
// 22.04.2022 BRD Added a modal dialog box to report issues to
//                the user.
// 22.04.2022 BRD Adapted for use within the React Maintenance
//                example.
// 25.04.2022 BRD Added key listener for the Enter key.
// 23.07.2022 BRD Integrated the React application with the new
//                Dancer Back-End.
// 15.08.2022 BRD Added state management using the browsers sessionStorage
//                object.
// 05.09.2022 BRD Added support for signing in with an email address.
// 02.10.2022 BRD Refactored authenticateUser() so that it uses the
//                axios async/await Promise properly.
// 15.11.2022 BRD "Dancers" are now referred to as "Registrants" so
//                all the table references have been updated. Not all
//                registrants dance so this allows a wider range of
//                users to be managed.
// 19.01.2023 BRD Upgraded the modal dialog and revised the code for
//                the key listeners.
// 03.03.2023 BRD Upgraded all data requests to provide JSON Web Tokens
//                (JWTs) during communication with the back end. This
//                addresses some outstanding issues on authentication
//                and authorisation.
// 28.05.2023 BRD Revised the key listener code to increase reliability.
//                This included implementing a proper state machine like
//                the versions used on other pages.
//
import React from "react"
import "bulma/css/bulma.css";
import { PageHeader } from "./PageHeader";
import { useState } from 'react';
import { getBaseURL } from './getBaseURL';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { editingStates } from "./Constants";
import eye from "./graphics/password_eye.gif";
import swal from 'sweetalert';
import Axios from 'axios';

const axios = Axios;
const baseURL = getBaseURL();

//
// SignIn()
// ========
export function SignIn() {
    const [UserID, setUserID] = useState("");
    const [JWT, setJWT] = useState("");
    const [UserAuthority, setUserAuthority] = useState("");
    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [Password, setPassword] = useState("");
    const [errors] = useState([]);
    const [SignInError, setSignInError] = useState(false);
    // Used to control the visibility of the password by switching the
    // input type between "text" and "password".
    const [PasswordVisibility, setPasswordVisibility] = useState("password");

    let navigate = useNavigate();

    document.addEventListener('keydown', keyListener);

    //
    // Editing state control
    // =====================
    // This section defines the state machine that controls the sign-in
    // process. The useState Hook ensures that the environment gets
    // re-configured each time the state changes.
    //
    const [editingState, setEditingState] = useState(editingStates.LOADING);
    useEffect(() => {
        switch (editingState) {
            case editingStates.LOADING:
                //console.log("Loading...");
                // This is the initial stage that allows the user to enter their registrant
                // ID or email and then supply their password.
                sessionStorage.setItem('userID', "");
                sessionStorage.setItem("FirstName", "");
                sessionStorage.setItem("LastName", "");
                sessionStorage.setItem("UserAuthority", "");
                sessionStorage.setItem("JWT", "");
                break;

            case editingStates.AUTHENTICATING:
                //console.log("Authenticating ...");
                let errors = {};
                var error = false;

                if (UserID === "") {
                    errors.UserID = "A registrant number or email address must be entered";
                    error = true;
                }
                if (Password === "") {
                    errors.Password = "A password must be entered";
                    error = true;
                }
                //setErrors(errors);
                if (error) {
                    setEditingState(editingStates.LOADING);
                } else {
                    authenticateUser(UserID, Password);
                }
                break;

            case editingStates.AUTHENTICATED:
                // This is set when the user has been authenticated and can begin using the
                // adminstration services inside.
                //console.log("Authenticated...");
                document.removeEventListener('keydown', keyListener);
                sessionStorage.setItem("userID", UserID);
                sessionStorage.setItem("FirstName", FirstName);
                sessionStorage.setItem("LastName", LastName);
                sessionStorage.setItem("UserAuthority", UserAuthority);
                sessionStorage.setItem("JWT", JWT);
                return navigate("/MainPage");

            case editingStates.NOT_AUTHENTICATED:
                console.log("Not Authenticated...");
                setSignInError(!SignInError);
                break;

            case editingStates.CHANGING_PASSWORD:
                // The user is requesting to change their password from the
                // Sign-In page. RA_BRD Not implemented yet.
                break;

            case editingStates.FORGOT_PASSWORD:
                // The user is requesting help since they have forgotten their
                // password. RA_BRD Not implemented yet.
                break;

            case editingStates.ERROR:
                swal("Signing in",
                    "This program has encountered a problem.\n\n" +
                    "The details have been logged and the site administrator has been notified.\n\n" +
                    "Please click OK to exit.");
                setEditingState(editingStates.LOADING);
                break;

            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editingState]);

    //
    // authenticateUser()
    // ==================
    // Authenticates the user by verifying that their registration ID number or their email address can be
    // located by the back-end and that their password matches the encrypted one stored in the database.
    //
    // If the user is authenticated, a JSON Web Token (JWT) is returned from the back-end. This is saved to
    // the session storage so that it can be used to authorise all subsequent data requests from the back
    // end.
    //
    const authenticateUser = async (UserID, Password) => {
        try {
            let response = await axios.get(baseURL + "authenticateUser?user_ID=" + encodeURIComponent(UserID) +
                "&password=" + encodeURIComponent(Password));
            if (response.status === 200) {
                // The user was found and their credentials were authenticated.
                console.log("\nauthenticate(): " + response.status + "\n");
                setUserID(response.data.registrant_ID);
                setFirstName(response.data.first_name);
                setLastName(response.data.last_name);
                setUserAuthority(response.data.user_authority);
                setJWT(response.data.JWT);
                setEditingState(editingStates.AUTHENTICATED);
            } else {
                setEditingState(editingStates.NOT_AUTHENTICATED);
            }
        } catch (err) {
            setEditingState(editingStates.NOT_AUTHENTICATED);
        }
    }

    //
    // keyListener()
    // =============
    // Sets a document key listener to detect the Enter key being pressed.
    // This is used to trigger the default submission behaviour for a
    // page whose elements are not wrapped in a form.
    //
    function keyListener(event) {
        var key = event.key;
        if (SignInError) {
            // The modal error dialog has control of the page.
            if (key === "Enter" || key === "Escape") {
                try {
                    event.stopPropagation();
                    setSignInError(SignInError => !SignInError);
                    setEditingState(editingStates.LOADING);
                } catch {
                    console.write("Signin key error E1");
                }
            }
        } else {
            // The Sign-In page is in control.
            if (key === 'Enter') {
                // Attempt to sign in.
                try {
                    event.stopPropagation();
                    setEditingState(editingStates.AUTHENTICATING);
                } catch {
                    console.write("Signin key error E2");
                }
            }
        }
    }

    //
    // ShowError()
    // ===========
    // Displays a modal error dialog box.
    //
    const ShowError = () => {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-3-tablet is-3-desktop is-3-widescreen">
                            <div className="modal is-active"
                                id="modalDialog">
                                <div className="modal-content has-background-blue">
                                    <div className="box" style={{ width: "490px" }}>
                                        <p style={{ fontSize: "20pt" }}>We were unable to sign you in</p>
                                        <br></br>
                                        <p>Either the Registrant number, the email address, or the password you entered is incorrect.</p>
                                        <br></br>
                                        <p>Are you a registered Dancesport member? If so, please check and re-enter your credentials and then click <b>OK</b> to try again.</p>
                                        <br></br>
                                        <p>Click <b>Reset Password</b> to receive an email to help you reset your password.</p>
                                        <br></br>
                                        <p>If you are a new member, click <b>Register</b> to set up your account.</p>
                                        <br></br>

                                        <div className="field">
                                            <button className="button is-danger"
                                                style={{ width: "100px" }}
                                                id="modalOK"
                                                onClick={() => {
                                                    setSignInError(false);
                                                    setEditingState(editingStates.LOADING);
                                                }} >
                                                OK
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    //
    // Main Sign-In Page
    // =================
    return (
        <section className="hero is-primary is-fullheight has-background-grey-lighter">
            <PageHeader />
            <div className="hero-body is-centered">
                <div className="container">
                    {/* Modal error dialog window */}
                    {SignInError ? (
                        <ShowError />
                    ) : null}

                    <section className="box has-background-light"
                        style={{position: "fixed", top: "30%", left: "35%", width: "550px" }}>
                        <p style={{ fontSize: "20pt" }}>Sign-in</p>
                        <br></br>
                        <div className="field">
                            <label className="label">
                                Your Registrant number or email address
                            </label>
                            <div className="control has-icons-left">
                                <input className="input"
                                    id="UserID"
                                    type="text"
                                    style={{ width: "380px" }}
                                    placeholder="nnnnn or email"
                                    autoComplete="new-password"
                                    value={UserID}
                                    onChange={e => setUserID(e.target.value)}
                                />
                                <span className="icon is-small is-left">
                                    <i className="fa fa-user" />
                                </span>
                                <p className="help is-danger is-size-6">{errors.UserID}&nbsp;</p>
                            </div>
                        </div>

                        <label className="label">
                            Password
                        </label>
                        <div className="field is-grouped">
                            <div className="field">
                                <div className="control has-icons-left">
                                    <input className = "input"
                                        type={PasswordVisibility}
                                        placeholder="********"
                                        style={{ width: "380px" }}
                                        autoComplete="new-password"
                                        value={Password}
                                        onChange={e => setPassword(e.target.value)}
                                        maxLength={10}
                                    />
                                    <span className="icon is-small is-left">
                                        <i className="fa fa-lock" />
                                    </span>
                                    <p className="help is-danger is-size-6">{errors.Password}&nbsp;</p>
                                </div>
                            </div>

                            <img src={eye}
                                style={{ width: "25px", height: "20px", marginLeft: "10px", marginTop: "10px"}}
                                alt="password reveal"
                                onClick={e => {
                                    if (PasswordVisibility === "password") {
                                        setPasswordVisibility("text");
                                    } else {
                                        setPasswordVisibility("password");
                                    }
                                }}
                            />
                        </div>

                        <br></br>

                        <div className="field">
                            <button className="button is-success"
                                id="SignIn"
                                style={{ width: "100px" }}
                                onClick={e => {
                                    setEditingState(editingStates.AUTHENTICATING);
                                }}>
                                Sign-in
                            </button>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    );
}
