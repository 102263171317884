//
// DANCESPORT PAGE HEADER
// ======================
// The standard header used in every page of the Dancesport
// application.
//
// Revision History
// ================
// 07.10.2022 BRD Original version.
//
import React from "react"
import "bulma/css/bulma.css";
import logo from "./graphics/Dancesport_logo.jpg";

export function PageHeader() {
	return (
		<React.Fragment>
			<h1 className="title has-text-black mx-6 mt-6 mb-6">
				<img src={logo}
					style={{ width: "100px", height: "100px" }}
					alt="site logo"
					href="#top"
				/>
				&nbsp; Dancesport
			</h1>
		</React.Fragment>
	);
};
