//
// MANAGE ADMINISTRATORS
// =====================
// Users are created and managed in the ManageRegistrants module. Some registrants are
// given elevated user priviledges to allow them to manage the Dancesport site.
//
// Revision History
// ================
// 04.07.2023 BRD Original version.
//
import React from "react"
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBaseURL } from './getBaseURL';
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import Axios from 'axios';

import "bulma/css/bulma.css";
import "./Dancesport.css";
import { PageHeader } from "./PageHeader";
import { editingStates, dialogs } from "./Constants";
import { CheckboxSelectionComponent, GridApi } from "ag-grid-community";
import { checkPropTypes } from "prop-types";
const baseURL = getBaseURL();
//
// ManageAdministrators()
// ======================
export function ManageAdministrators() {
   const [errors, setErrors] = useState([]);
   const axios = Axios;
   const navigate = useNavigate();
   const JWT = sessionStorage.getItem('JWT');

   let gridApi;

   //
   // Authenticate user
   // =================
   // Checks to see if the local storage has a UserID value set to ensure
   // that only authenticated users can access this page. Users who are not
   // authenticated are directed back to the Sign-on page.
   //
   const UserID = sessionStorage.getItem('userID');
   useEffect(() => {
      if (!UserID) {
         return navigate("/");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [UserID]);
   //
   //
   // Editing state control
   // =====================
   // This section implements a state machine that controls the editing
   // cycle of the record while it is being loaded, changed, updated, or
   // cancelled. The useState hook ensures that the environment gets
   // re-configured each time the state changes.
   //
   const [editingState, setEditingState] = useState(editingStates.LOADING);
   useEffect(() => {
      switch (editingState) {
         case editingStates.LOADING:
            getAdministrators();
            break;

            case editingStates.CANCELLING:
               //if (isChanged()) {
               //    showYesNoDialog(dialogs.SHOW_DIALOG, dialogs.YES);
               //} else {
                   setEditingState(editingStates.EXITING);
               //}
               break;

           case editingStates.EXITING:
               return navigate("/MainPage");

           case editingStates.UPDATING:
               console.log(rowData);
               console.log("RowID = " + getRowId);
               //if (validate()) {
               //    updateConfiguration();
               //    return navigate("/MainPage");
               //} else {
                   setEditingState(editingStates.EDITING);
               //}
               break;

           case editingStates.ERROR:
               // This user may not be authorised to access this data
               setEditingState(editingStates.EXITING);
               break;

         default:
            break;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [editingState]);

   // value={cValue}
   // checked={cValue}
   // onChange={e => setcValue(cValue => !cValue)}

   const [rowData, setRowData] = useState([]);


   //const [cValue, setcValue] = useState(true);
   //onChange={e => setRowData(!rowData.user_authority)}  
   // eslint-disable-next-line
   const [columnDefs] = useState([
      {
         field: 'registrant_ID', 'width': 120, headerName: "Registrant", filter: true,
         sortable: true, multiselect: true, ScellStyle: { fontSize: "12pt" }
      },

      {
         field: 'first_name', 'width': 150, headerName: "First name", filter: true,
         sortable: true, editable: true, cellStyle: { fontSize: "12pt" }
      },

      {
         field: 'last_name', 'width': 220, headerName: "Last Name", filter: true,
         sortable: true, cellStyle: { fontSize: "12pt" }
      },

      {
         field: 'user_authority', 'width': 150, headerName: "Administrator", 
         cellEditor: 'agCheckboxCellEditor',        
         cellRenderer: checkboxRenderer,
      },       
   ]);  
   
   const [cValue, setcValue] = useState(false)
   
   function checkboxRenderer() {
      return (
         <input
            type="checkbox"
            style={{ width: "20px", height: "20px", verticalAlign: "middle"}}            
         />
      );
   }

   
   

   //
   // getAdministrators
   // =================
   // Loads the list of registrants who are administrators into the editing
   // grid.
   //
   const getAdministrators = async () => {
      try {
         let response = await axios.get(baseURL + "getAdministrators?JWT=" + JWT);
         console.log("getAdministrators() response.status: " + response.status);
         if (response.status === 200) {
            console.log(response.data);
            console.log(response.data.length  + "[0]=" + response.data[0].registrant_ID);
            for (var ptr = 0; ptr < response.data.length; ptr++) {
               if (response.data[ptr].user_authority === "A") {
                  response.data[ptr].user_authority  = true;
               } else {
                  response.data[ptr].user_authority  = false;
               }               
            }            
            setRowData(response.data);
            console.log(response.data);
         } else {
         }
      } catch (err) {
         console.log("getAdministrators() error:" + err.status);
      }
      //setIsLoaded(true)

   };

   const getRowId = params => params.data.id;

   //
   // SelectAdministrator
   // ===================
   function SelectAdministrator() {

   }

   //
   // ManageAdministrators
   // ====================
   // This renders and returns the main page. All of the referenced functions have already
   // been declared by this stage.
   //
   //onCellClicked={SelectAdministrator} 
   return (
      <section className="hero is-primary is-fullheight has-background-grey-lighter">
         <PageHeader />
         <div className="container is-centered">
            <section className="has-background-light box"
               style={{ width: "800px" }} >
               <p className="is-size-3 mx-6 mt-0">
                  Manage Administrators
               </p>
               <br></br>

               <div className="ag-theme-alpine"
                  style={{ height: "550px", width: "650px", marginLeft: "50px" }}>
                  <AgGridReact
                     id="AgGridReact"
                     rowData={rowData}
                     columnDefs={columnDefs}
                     getRowId ={getRowId} 
                     />
               </div>

               <div className="field is-grouped mx-6 mt-5">
                  <div className="field pr-3">
                     <button className="button is-success"
                        type="submit"
                        id="Update"
                        style={{ width: "100px" }}
                        onClick={e => setEditingState(editingStates.UPDATING)} >
                        Update
                     </button>
                  </div>

                  <div className="field">
                     <button className="button is-success"
                        id="Cancel"
                        style={{ width: "100px" }}
                        onClick={() => { setEditingState(editingStates.CANCELLING) }}>
                        Cancel
                     </button>
                  </div>
               </div>
            </section>
         </div>
      </section>
   );
}
