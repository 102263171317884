//
// DANCESPORT
// ==========
// The React dancer management app for Dancesport New Zealand.
// This application uses React to implement the front-end and
// Express to implement the back-end connection to the ProgreSQL
// database that stores all the Dancesport information.
//
// Revision History
// ================
// 22.04.2022 BRD Original version.
// 01.09.2022 BRD Ported into a separate Dancesport production
//                version that uses code from the original app
//                prototypes.
// 28.05.2023 BRD Published first version as 1.0 and deployed
//                it to www.rockweatherdev.com. Incremented
//                version to 1.01 to reflect minor bug fixes
//                and tidy-ups.
// 20.06.2023 BRD Published maintenence release 1.02 with a set
//                of minor bug fixes.
// 04.12.2023 BRD Published release 1.03 that includes additional
//                database fields and improved error trapping.
//
// 17.06.2024 BRD Published release 1.04 that includes the
//                functionality to Manage Administrators and
//                specify fees via the Manace Configuration
//                application. This release also includes minor
//                bug fixes.
// nn.nn.nnnn BRD Published release 1.05 that includes ...
//
import React from "react";
import 'bulma/css/bulma.css';
import 'react-bulma-components';
import { Routes, Route } from "react-router-dom";
import { SignIn } from "./SignIn.js";
import { MainPage } from "./MainPage.js";
import { ManageRegistrants } from "./ManageRegistrants.js";
import { ManageConfiguration } from "./ManageConfiguration.js";
import { ChangePassword } from "./ChangePassword.js";
import { ManageAdministrators } from "./ManageAdministrators.js";
import { About } from "./About.js";

// Sets the current version of the application and makes it
// available to other pages when they need to display it.
const version = "1.04";
sessionStorage.setItem("version", version);

//
// Dancesport()
// ============
// Note the use of the <Routes> element which wraps each individual <Route> to provide a path
// to each separate page with its own functionality. This relies on the page definitions
// imported above.
//
function Dancesport() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/MainPage" element={<MainPage />} />
        <Route path="/ManageRegistrants" element={<ManageRegistrants />} />
        <Route path="/ManageConfiguration" element={<ManageConfiguration />} />
        <Route path="/ChangePassword" element={<ChangePassword />} />
        <Route path="/ManageAdministrators" element={<ManageAdministrators />} />
        <Route path="/About" element={<About />} />
      </Routes>
    </div>
  );
}
export default Dancesport;
