//
// DANCESPORT LOOKUP LISTS
// =======================
// React fragments for the option lists used by the
// combo boxes and other components in ManageRegistrants.
//
// Revision History
// ================
// 10.09.2022 BRD Original version as ComboLists.js
// 19.10.2022 BRD Renamed as LookupLists.js now that
//                it is going to be used by other
//                components.
//
import React from "react";

//
// Salutations
// ===========
export function Salutations() {
    return (
        <React.Fragment>
            <option value="Miss">
                Miss
            </option>
            <option value="Ms">
                Ms
            </option>
            <option value="Mrs">
                Mrs
            </option>
            <option value="Mr">
                Mr
            </option>
            <option value="Dr">
                Dr
            </option>
            <option value="Lady">
                Lady
            </option>
            <option value="Lord">
                Lord
            </option>
            <option value="Sir">
                Sir
            </option>
            <option value="Reverend">
                Reverend
            </option>
            <option value="Rabbi">
                Rabbi
            </option>
            <option value="Not specified">
                Not specified
            </option>
            <option value=" ">
            </option>
        </React.Fragment>
    );
}

//
// Genders
// =======
export function Genders() {
    return (
        <React.Fragment>
            <option value = "F">
                Female
            </option>
            <option value = "M">
                Male
            </option>
            <option value = "O">
                Other
            </option>
            <option value = "N">
                Not specified
            </option>
            <option value = " ">
                &nbsp;
            </option>
        </React.Fragment >
    );
}

//
// UserAuthority
// =============
export function UserAuthorities() {
    return (
        <React.Fragment>
            <option value = "U">
                Standard user
            </option>
            <option value = "A">
                Administrator
            </option>
            <option value = " ">
                &nbsp;
            </option>
        </React.Fragment >
    );
}

//
// RegistrantStatuses
// ==================
export function RegistrantStatuses() {
    return (
        <React.Fragment>
            <option value = "R">
                Registered
            </option>
            <option value = "U">
                Unregistered
            </option>
            <option value = "D">
                Deceased
            </option>
            <option value = " ">
                &nbsp;
            </option>
        </React.Fragment>
    );
}

//
// TeacherStatuses
// ===============
export function TeacherStatuses() {
    return (
        <React.Fragment>
            <option value = "R">
                Registered
            </option>
            <option value = "P">
                Pending registration
            </option>
            <option value = "U">
                Unregistered
            </option>
            <option value = " ">
                &nbsp;
            </option>
        </React.Fragment>
    );
}

//
// Countries
// =========
// Countries applicable for internationally-qualified
// professional options
//
export function Countries() {
    return (
        <React.Fragment>
            <option value = "AUS">
                Australia
            </option>
            <option value = "USA">
                United States
            </option>
            <option value = "UK">
                United Kingdom
            </option>
            <option value = "NQ">
                Not qualified
            </option>
        </React.Fragment>
    );
}

//
// Standard
// ========
export function Standard() {
    return (
        <React.Fragment>
            <option value = "WA">
                Waltz
            </option>
            <option value = "TA">
                Tango
            </option>
            <option value = "SF">
                Slow Foxtrot
            </option>
            <option value = "QS">
                Quickstep
            </option>
            <option value = "VW">
                Viennese Waltz
            </option>
        </React.Fragment>
    );
}
